import { LoaderFunctionArgs, redirect } from 'react-router';
import AlarmsAndErrorsPage from '../pages/Fleet/AlarmsAndErrors';
import Consumables from '../pages/Fleet/Consumables';
import Devices from '../pages/Fleet/Devices/index';
import Overview from '../pages/Fleet/Overview';
import ScrollTopOutlet from '../core/ScrollTopOutlet';
import DevicesOtherTwoTone from '@mui/icons-material/DevicesOtherTwoTone';
import { getPath } from '@/shared/utils';
import { ROUTES } from '@/shared/constants';
import Enhancements from '../pages/Fleet/Consumables/Enhancements';
import CO2 from '../pages/Fleet/Consumables/CO2';
import Chemicals from '../pages/Fleet/Consumables/Chemicals';
import Filters from '../pages/Fleet/Consumables/Filters';
import Membranes from '../pages/Fleet/Consumables/Membranes';
import Ozone from '../pages/Fleet/Consumables/Ozone';
import SanitizationKit from '../pages/Fleet/Consumables/SanitizationKit';
import UVCLight from '../pages/Fleet/Consumables/UVCLight';
import WaterBottles from '../pages/Fleet/Consumables/WaterBottles';
import DevicePage from '../pages/Fleet/Devices/Device';
import List from '../pages/Fleet/Devices/List';
import DevicesMap from '../pages/Fleet/Devices/MapDevices';
import DeviceOverview from '../pages/Fleet/Devices/Device/Overview';
import Alarms from '../pages/Fleet/Devices/Device/Alarms';
import Errors from '../pages/Fleet/Devices/Device/Errors';
import Events from '../pages/Fleet/Devices/Device/Events';
import DeviceConsumables from '../pages/Fleet/Devices/Device/Consumables';
import Telemetry from '../pages/Fleet/Devices/Device/Telemetry';
import OtaJobs from '../pages/Fleet/Devices/Device/OtaJobs';
import LoyaltyPrograms from '../pages/Fleet/Devices/Device/LoyaltyPrograms';
import AlarmsPanel from '@/components/Fleet/AlarmsAndErrors/Alarms/AlarmsPanel';
import ErrorsPanel from '@/components/Fleet/AlarmsAndErrors/Errors/ErrorsPanel';

const FleetRoutes = {
  path: `/${ROUTES.FLEET.fragment}`,
  label: 'Fleet',
  element: <ScrollTopOutlet />,
  icon: <DevicesOtherTwoTone />,
  children: [
    {
      index: true,
      loader: () => redirect(getPath('FLEET_OVERVIEW')),
    },
    {
      path: ROUTES.FLEET_OVERVIEW.fragment,
      label: 'Overview',
      element: <Overview />,
    },
    {
      path: ROUTES.FLEET_CONSUMABLES.fragment,
      element: <Consumables />,
      label: 'Consumables',
      children: [
        {
          index: true,
          loader: () => redirect(getPath('FLEET_CONSUMABLES_ENHANCEMENTS')),
        },
        {
          path: ROUTES.FLEET_CONSUMABLES_ENHANCEMENTS.fragment,
          element: <Enhancements />,
        },
        {
          path: ROUTES.FLEET_CONSUMABLES_CO2.fragment,
          element: <CO2 />,
        },
        {
          path: ROUTES.FLEET_CONSUMABLES_CHEMICALS.fragment,
          element: <Chemicals />,
        },
        {
          path: ROUTES.FLEET_CONSUMABLES_FILTER.fragment,
          element: <Filters />,
        },
        {
          path: ROUTES.FLEET_CONSUMABLES_MEMBRANES.fragment,
          element: <Membranes />,
        },
        {
          path: ROUTES.FLEET_CONSUMABLES_OZONE.fragment,
          element: <Ozone />,
        },
        {
          path: ROUTES.FLEET_CONSUMABLES_SANITIZATIONKIT.fragment,
          element: <SanitizationKit />,
        },
        {
          path: ROUTES.FLEET_CONSUMABLES_UVCLIGHT.fragment,
          element: <UVCLight />,
        },
        {
          path: ROUTES.FLEET_CONSUMABLES_WATERBOTTLES.fragment,
          element: <WaterBottles />,
        },
      ],
    },
    {
      path: ROUTES.FLEET_DEVICES.fragment,
      label: 'Devices',
      element: <Devices />,
      children: [
        {
          index: true,
          loader: () => redirect(getPath('FLEET_DEVICES_LIST')),
        },
        {
          path: ROUTES.FLEET_DEVICES_LIST.fragment,
          element: <List />,
        },
        {
          path: ROUTES.FLEET_DEVICES_MAP.fragment,
          element: <DevicesMap />,
        },
        {
          path: ROUTES.FLEET_DEVICE.fragment,
          element: <DevicePage />,
          children: [
            {
              index: true,
              loader: ({ params }: LoaderFunctionArgs) =>
                redirect(getPath('FLEET_DEVICE_OVERVIEW', { deviceId: params.deviceId ?? null })),
            },
            {
              path: ROUTES.FLEET_DEVICE_OVERVIEW.fragment,
              element: <DeviceOverview />,
            },
            {
              path: ROUTES.FLEET_DEVICE_ALARMS.fragment,
              element: <Alarms />,
            },
            {
              path: ROUTES.FLEET_DEVICE_ERRORS.fragment,
              element: <Errors />,
            },
            {
              path: ROUTES.FLEET_DEVICE_EVENTS.fragment,
              element: <Events />,
            },
            {
              path: ROUTES.FLEET_DEVICE_CONSUMABLES.fragment,
              element: <DeviceConsumables />,
            },
            {
              path: ROUTES.FLEET_DEVICE_TELEMETRY.fragment,
              element: <Telemetry />,
            },
            {
              path: ROUTES.FLEET_DEVICE_OTA_JOBS.fragment,
              element: <OtaJobs />,
            },
            {
              path: ROUTES.FLEET_DEVICE_LOYALTY_PROGRAMS.fragment,
              element: <LoyaltyPrograms />,
            },
          ],
        },
      ],
    },
    {
      path: ROUTES.FLEET_ALARMS_ERRORS.fragment,
      element: <AlarmsAndErrorsPage />,
      label: 'Alarms and errors',
      children: [
        {
          index: true,
          loader: () => redirect(getPath('FLEET_ALARMS')),
        },
        {
          path: ROUTES.FLEET_ALARMS.fragment,
          element: <AlarmsPanel />,
        },
        {
          path: ROUTES.FLEET_ERRORS.fragment,
          element: <ErrorsPanel />,
        },
      ],
    },
    // TODO
    // {
    //   path: 'interventions',
    //   element: <Interventions />,
    //   label: 'Interventions',
    // },
    // {
    //   path: 'glossary',
    //   element: <Glossary />,
    //   label: 'Glossary',
    // },
  ],
};

export default FleetRoutes;
